.modal {
    position: relative;
    width: auto;
    height: auto;
    max-width: 900px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    background: #111;
    overflow: hidden;
    border-radius: 8px;
    transition: all 400ms ease-in-out 2s;
    animation: fadeIn 400ms;
  }
  .modal__poster-img {
    margin-left: 5%;
    margin-top: 5%;
    height: 250px;
  }
  .modal__content {
    padding: 10px;
  }
  .modal__header {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
  }
  .modal__title {
    padding: 0;
    font-size: 30px;
    margin: 16px 0;
  }
  .modal__details {
    font-weight: 600;
    font-size: 18px;
  }
  .modal__overview {
    font-size: 20px;
    line-height: 1.5;
  }
  .modal__user-perc {
    color: #46d369;
  }
  .modal::-webkit-scrollbar {
    display: none;
    visibility: hidden;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .modal {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .wrapper-modal {
    z-index: -1;
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .presentation {
    z-index: 1200;
    position: absolute;
    top: 100;
    left: 100;
    background: rgba(0, 0, 0, 0.4);
  }
  .modal-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 1000;
  }
  @media screen and (max-height: 768px) {
    .wrapper-modal {
      align-items: unset;
      padding-top: 2rem;
    }
    .modal {
      overflow-y: scroll;
    }
  }
  @media screen and (max-width: 768px) {
    .modal__overview {
      font-size: 16px;
    }
    .modal__details {
      font-size: 16px;
    }
    .wrapper-modal {
      padding: 0;
    }
    .modal {
      overflow-y: scroll !important;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  