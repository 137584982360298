.grid {
    margin: 20px;
}

.title {
    color: white;
    margin-top: 10px;
}

.grid__posters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    scroll-behavior: smooth;
}

.grid__posters::-webkit-scrollbar {
    display: none;
}
.grid__poster {
    object-fit: contain;
    width: 100%;
    max-height: 144px;
    margin-right: 10px;
    transition: transform 450ms;
    border-radius: 4px;
}
.grid__poster:hover {
    transform: scale(1.08);
    cursor: pointer;
}
.grid__posterLarge {
    max-height: 320px;
}
.grid__posterLarge:hover {
    transform: scale(1.1);
    opacity: 1;
}